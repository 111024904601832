import { PLANNER_PORTAL_URL } from 'services/connections.service';
import { getURLPath } from 'services/http.service';

export const PAGE_NAME = {
  CATEGORIES: {
    label: 'CATEGORIES',
    template: '/categories'
  },
  CATEGORY_MANAGEMENT: {
    label: 'CATEGORY_MANAGEMENT',
    template: '/category-management'
  },
  CATEGORY_MASTER_ATTRIBUTE: {
    label: 'CATEGORY_MASTER_ATTRIBUTE',
    template: '/category-management/master-attribute'
  },
  CATEGORY_MASTER_ATTRIBUTE_GROUP: {
    label: 'CATEGORY_MASTER_ATTRIBUTE_GROUP',
    template: '/category-management/master-attribute-group'
  },
  CATEGORY_MASTER_ATTRIBUTE_GROUP_CREATE: {
    label: 'CATEGORY_MASTER_ATTRIBUTE_GROUP_CREATE',
    template: '/category-management/master-attribute-group/create'
  },
  COLLECTIONS: {
    label: 'COLLECTIONS',
    template: '/collections'
  },
  COLLECTION_MANAGEMENT: {
    label: 'COLLECTION_MANAGEMENT',
    template: '/collection-management'
  },
  CREATE_CATEGORY_MASTER_ATTRIBUTE: {
    label: 'CREATE_CATEGORY_MASTER_ATTRIBUTE',
    template: '/category-management/master-attribute/create'
  },
  CREATE_COLLECTIONS: {
    label: 'CREATE_COLLECTIONS',
    template: '/collections/create'
  },
  CREATE_PARTNERS: {
    label: 'CREATE_PARTNERS',
    template: '/partners/create'
  },
  CREATE_PLANNER: {
    label: 'CREATE_PLANNER',
    template: '/control/users/create'
  },
  CREATE_PRODUCTS: {
    label: 'CREATE_PRODUCTS',
    template: '/products/create'
  },
  CREATE_PROMO_CODES: {
    label: 'CREATE_PROMO_CODES',
    template: '/promocodes/create'
  },
  DASHBOARDS: {
    label: 'DASHBOARDS',
    template: '/dashboard'
  },
  DATA_EXPORTS: {
    label: 'DATA_EXPORTS',
    template: '/data-exports'
  },
  EDIT_ATTRIBUTE_GROUP: {
    label: 'EDIT_ATTRIBUTE_GROUP',
    template: '/categories/:categoryId/edit/attributes-group'
  },
  EDIT_ATTRIBUTE_GROUP_ASSIGN: {
    label: 'EDIT_ATTRIBUTE_GROUP_ASSIGN',
    template: '/categories/:categoryId/edit/attributes-group/assign'
  },
  EDIT_CATEGORY: {
    label: 'EDIT_CATEGORY',
    template: '/categories/:categoryId/edit'
  },
  EDIT_CATEGORY_ATTRIBUTE_GROUP: {
    label: 'EDIT_CATEGORY_ATTRIBUTE_GROUP',
    template:
      '/categories/:categoryId/edit/attributes-group/:attributeGroupId/edit-category-attribute-group'
  },
  EDIT_CATEGORY_BY_SECTION: {
    label: 'EDIT_CATEGORY_BY_SECTION',
    template: '/categories/:categoryId/edit/:section'
  },
  EDIT_CATEGORY_MASTER_ATTRIBUTE: {
    label: 'EDIT_CATEGORY_MASTER_ATTRIBUTE',
    template: '/category-management/master-attribute/edit/:attributeId'
  },
  EDIT_COLLECTIONS: {
    label: 'EDIT_COLLECTIONS',
    template: '/collections/:collectionId/edit'
  },
  EDIT_MASTER_ATTRIBUTE_GROUP: {
    label: 'EDIT_MASTER_ATTRIBUTE_GROUP',
    template: '/category-management/master-attribute-group/:attributeId/edit'
  },
  EDIT_ORDERS: {
    label: 'EDIT_ORDERS',
    template: '/orders/:orderId/details'
  },
  EDIT_ORDERS_BY_ACTIVE_TAB: {
    label: 'EDIT_ORDERS_BY_ACTIVE_TAB',
    template: '/orders/:orderId/details/:activeTab'
  },
  EDIT_PARTNERS: {
    label: 'EDIT_PARTNERS',
    template: '/partners/:partnerId/edit'
  },
  EDIT_PARTNERS_BY_SECTION: {
    label: 'EDIT_PARTNERS_BY_SECTION',
    template: '/partners/:partnerId/edit/:section'
  },
  EDIT_PRODUCTS: {
    label: 'EDIT_PRODUCTS',
    template: '/products/:productId/edit'
  },
  EDIT_PRODUCTS_BY_SECTION: {
    label: 'EDIT_PRODUCTS_BY_SECTION',
    template: '/products/:productId/edit/:section'
  },
  EDIT_PROMO_CODES: {
    label: 'EDIT_PROMO_CODES',
    template: '/promocodes/:promocodeId/edit'
  },
  EVENT_CARTS: {
    label: 'EVENT_CARTS',
    template: '/carts'
  },
  HOME: {
    label: 'HOME',
    template: '/'
  },
  LOGIN: {
    label: 'LOGIN',
    template: '/login'
  },
  NOT_FOUND: {
    label: 'NOT_FOUND',
    template: '/404'
  },
  OM_CONFIG: {
    label: 'OM_CONFIG',
    template: '/om-config'
  },
  PARTNERS: {
    label: 'PARTNERS',
    template: '/partners'
  },
  PLANNERS: {
    label: 'PLANNERS',
    template: '/control/users'
  },
  PRODUCTS: {
    label: 'PRODUCTS',
    template: '/products'
  },
  PROMO_CODES: {
    label: 'PROMO_CODES',
    template: '/promocodes'
  }
};

export const getPageURL = ({
  excludeBaseUrl = true,
  pageName,
  pathParams = {},
  queryParams = {}
}) => {
  const pathname =
    PAGE_NAME[pageName]?.template ?? PAGE_NAME.NOT_FOUND.template;

  const urlSuffix = getURLPath({
    pathname,
    pathParams,
    queryParams
  });

  const urlPrefix = !excludeBaseUrl ? PLANNER_PORTAL_URL : '';
  return `${urlPrefix}${urlSuffix}`;
};
